import React from "react"

const Tag = ({ text }: { text: string }): JSX.Element => {
    return (
        <span className="item__tag">
            {text}
        </span>
    )
}

export default Tag
