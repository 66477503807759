import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const RoarImage = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      roarImage: file(relativePath: { eq: "roar.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.roarImage.childImageSharp.fluid}
      imgStyle={{
        objectFit: "cover",
        height: "350px",
        display: "block"
      }}
    />
  )
}

export default RoarImage
