import React from "react"

import listOfPastJobs from "./data"

const About = ({ id }: { id: string }): JSX.Element => {
  return (
    <div id={id}>
      <div className="about-container">
        <h1 className="about-title">About</h1>
        <p className="about-description">
          I’m a freelance web developer from Israel. I like to code in Flask,
          NodeJS, and React, but I have experience with a vast set of tools and
          languages. I’m not afraid of DevOps, testing and hard work. Let me
          help your vision become a reality. <a href="#contact">Hire me</a>.
        </p>
        <ul className="timeline-list">
          {listOfPastJobs && listOfPastJobs.map(jobItem => (
            <li key={jobItem.id}>
              <div className="content">
                <p>{jobItem.date}</p>
                <h3>{jobItem.title}</h3>
                <p>{jobItem.company}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default About
