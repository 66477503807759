import React from "react"

const LinkIcon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 112.55 122.88"
      height="36"
      width="36"
    >
      <path
        d="M104.41 6.1c6.26 5.13 6.93 11.83 7.62 13.46l.34 2.5c.9 5.39-1.65 12.75-5.58 17.38L89.2 59.84c-6.76 7.84-18.04 10.44-27.48 6.37l-.03.04c3.45 5.63 3.15 9.64 3.46 10.57.9 5.41-1.65 12.74-5.58 17.38l-17.6 20.4c-8.53 9.89-23.58 11.1-33.53 2.61C3.4 112.17.6 107.9.07 100.72-.4 94.48 1.6 88.35 5.66 83.54l17.92-20.79c5.01-5.14 7.5-5.86 13.33-7.47l2.5-.34 10.66 1.56c.22.08.44.18.65.27l.03-.04c-5.35-8.71-4.57-20.11 2.14-27.97L70.48 8.37C74.59 3.6 80.47.66 86.63.18 92-.71 99.4 1.82 104.01 5.76l.4.34zM74.23 51.71l-3.66 4.24.64.01h.02l.6-.02h.01l.14-.01H72c2.11-.16 4.19-.88 5.96-2.14.34-.24.68-.51 1.02-.82.3-.27.62-.59.93-.95l.12-.13 17.45-20.24c1.47-1.7 2.36-3.75 2.68-5.86.07-.44.11-.87.13-1.26.02-.41.01-.85-.01-1.28v-.05l-.01-.11c-.16-2.11-.88-4.19-2.14-5.96-.24-.34-.51-.67-.78-.97l-.03-.04c-.29-.32-.61-.64-.94-.94l-.06-.05-.05-.05-.11-.08a11.57 11.57 0 00-5.78-2.61h-.03c-.43-.06-.85-.11-1.24-.12a13.5 13.5 0 00-1.27.01h-.07l-.1.01c-2.11.16-4.19.88-5.96 2.14-.34.24-.68.51-.98.78l-.03.03c-.33.29-.64.61-.94.95l-.12.13L62.2 36.55c-1.47 1.7-2.36 3.75-2.68 5.86-.06.43-.11.86-.12 1.26-.02.41-.01.85.01 1.28l.01.15v.03c.03.46.09.91.18 1.37l3.58-4.15.1-.12.13-.14.02-.02a7.21 7.21 0 014.79-2.3c1.78-.13 3.62.39 5.1 1.6l.02.01.09.08.02.02.02.02.01.01.02.01.07.06c2.06 1.83 2.82 4.6 2.21 7.13-.12.5-.3 1-.54 1.48-.22.46-.51.9-.83 1.31l-.02.02-.03.04-.01.02-.1.12-.02.01zM40.06 80.23c2.33 2.01 5.88 1.75 7.89-.58l5.58-6.47c.65 1.45 1.04 3 1.16 4.57.25 3.44-.79 6.97-3.19 9.75l-17.46 20.24c-2.4 2.79-5.73 4.34-9.16 4.59-3.38.25-6.84-.75-9.59-3.05l-.16-.14c-2.78-2.4-4.34-5.73-4.59-9.16-.25-3.4.76-6.89 3.1-9.65l.09-.1 17.25-20 .21-.24c2.4-2.78 5.73-4.34 9.16-4.59 1.58-.12 3.18.04 4.71.47l-5.58 6.47c-2.01 2.33-1.75 5.88.58 7.89z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default LinkIcon
