export default [
  {
    "id": 4,
    "date": "2020 - PRESENT",
    "title": "Software Engineer (Freelance)",
    "company": "Toptal"
  },
  {
    "id": 3,
    "date": "2019 - 2020",
    "title": "Team Lead (Freelance)",
    "company": "Xprt.io"
  },
  {
    "id": 2,
    "date": "2015 - 2018",
    "title": "Software Engineer",
    "company": "Mellanox Technologies"
  },
  {
    "id": 1,
    "date": "2014 - 2015",
    "title": "TA: Python, Java, C and C++",
    "company": "The Hebrew University of Jerusalem"
  },
  {
    "id": 0,
    "date": "2011 - 2015",
    "title": "B.Sc in Computer Science",
    "company": "The Hebrew University of Jerusalem"
  }
]
