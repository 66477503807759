import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const MHIImage = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      mhiImage: file(relativePath: { eq: "morehumaninternet.org.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.mhiImage.childImageSharp.fluid}
      imgStyle={{
        objectFit: "cover",
        height: "350px",
        display: "block"
      }}
    />
  )
}

export default MHIImage
