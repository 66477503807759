import React from "react"

const Contact = ({ id }: { id: string }): JSX.Element => {
  return (
    <div id={id}>
      <div className="contact-container">
        <h1>How can I help you?</h1>
        <form
          name="contact"
          method="POST"
          className="contact-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {/* A hidden input field for Netlify Form */}
          <input type="hidden" name="form-name" value="contact" />
          <label>
            Name
            <input type="text" name="name" id="name" />
          </label>
          <label>
            Email
            <input type="email" name="email" id="email" />
          </label>
          <label>
            Subject
            <input type="text" name="subject" id="subject" />
          </label>
          <label>
            Message
            <textarea name="message" id="message" rows="5" />
          </label>
          <button className="btn" type="submit">
            Send
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact
