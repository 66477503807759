import React from "react"

import Layout from "../components/layout"
import HomePageImage from "../components/homepage-image"
import SEO from "../components/seo"
import Contact from "../components/contact"
import About from "../components/about/about"
import Projects from "../components/projects/projects"
import handleNavClick from "../components/handle-nav-click"

const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="homepage">
        <h2 className="short-description">
          Building custom-made enterprise web applications
        </h2>
        <h2 className="call-to-action">
          Do you need help with your application?
        </h2>
        <button
          className="btn hire-me-btn upper"
          onClick={handleNavClick("#contact")}
        >
          Hire me
        </button>
        <div className="profile-img">
          <HomePageImage />
        </div>
      </div>
      <About id="about" />
      <Projects id="projects" />
      <Contact id="contact" />
    </Layout>
  )
}

export default IndexPage
