import React from "react"
import MHIImage from './mhi-image'
import RoarImage from "./roar-image"
import GitHubIcon from "../icons/github"
import LinkIcon from "../icons/link"
import Tag from "./tag"

const Projects = ({ id }: { id: string }): JSX.Element => {
    return (
        <div id={id}>
            <div className="projects-container">
                <h1 className="projects-title">Recent Projects</h1>
                <div className="projects-list">
                    <div className="projects-item">
                        <p className="item__title">More Human Internet</p>
                        <MHIImage />
                        <div className="item__tags">{["Gatsby", "TypeScript", "React", "Sass"].map(tag => <Tag key={tag} text={tag} />)}</div>
                        <p className="item_description">More Human Internet is a nonprofit organization building a free, public platform that facilitates dialog between the users and maintainers of the web to promote civility, transparency, and the greater good.</p>
                        <div className="item__links">
                            <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://morehumaninternet.org/"><LinkIcon /></a>
                            <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://github.com/morehumaninternet/frontend"><GitHubIcon /></a>
                        </div>
                    </div>
                    <div className="projects-item">
                        <p className="item__title">Roar</p>
                        <RoarImage />
                        <div className="item__tags">{["TypeScript", "React", "Redux", "Sass", "PostgreSQL", "Koa", "Knex"].map(tag => <Tag key={tag} text={tag} />)}</div>
                        <p className="item_description">Roar is a free, non-profit web extension that automatically captures a snapshot of any internet issue you may be facing and drafts a tweet to the site’s maintainer.</p>
                        <div className="item__links">
                            <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://github.com/morehumaninternet/roar-extension"><LinkIcon /></a>
                            <a className="svg-anchor" target="_blank" rel="noopener noreferrer" href="https://github.com/morehumaninternet/roar-extension"><GitHubIcon /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Projects
